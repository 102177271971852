import * as React from "react";
import { Card, Container, Box, Divider, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilmCatalog from "./FilmCatalog";
import img1 from "./../../images/Movie/Jeen-yuhsMed.jpg";
import img2 from "./../../images/Movie/MindhunterMed.jpg";
import img4 from "./../../images/imdb-icon.png";
import img3 from "./../../images/Movie/WTCCover.jpg";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  color: "white",
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const AllFilmSection = () => {
  let navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div style={{ background: "#141b22" }}>
      <Grid
        container
        sx={{
          display: "flex",
          background: "#141b22",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Typography
            sx={{
              display: { lg: "flex", md: "flex", sm: "flex", xs: "none" },
              color: "white",
              mt: "1em",
              fontSize: "2.5rem",
            }}
          >
            <strong style={{ justifyContent: "center" }}>
              {" "}
              Film and Television
            </strong>{" "}
          </Typography>
          <Typography
            sx={{
              display: { lg: "none", md: "none", sm: "none", xs: "flex" },
              textAlign: "center",
              color: "white",
              mt: "1em",
              fontSize: "2.2rem",
            }}
          >
            <strong style={{ justifyContent: "center" }}>
              {" "}
              Film and <br></br> Television
            </strong>{" "}
          </Typography>
          <Box
            onClick={() => navigate("")}
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              ml: "1em",
              mt: "2em",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <a
              href="https://www.imdb.com/name/nm0889227/"
              aria-label="GinoV.com"
              rel="noopener"
              target="_blank"
            >
              <img
                style={{
                  width: 60,
                  height: 60,
                }}
                src={img4}
                alt=""
              />
            </a>
          </Box>
        </Box>
      </Grid>

      {/* Picture for Xs - */}
      <Box
        sx={{
          display: { lg: "none", md: "none", sm: "none", xs: "flex" },
          justifyContent: "center",
          mt: "2em",
        }}
      >
        <img
          style={{
            width: 220,
            height: 350,
          }}
          src={img1}
          alt=""
        />
      </Box>

      {/* Picture for Lg - */}
      <Card variant="basic" sx={{ background: "#141b22" }}>
        <Box sx={{ mt: "3em", ml: "1em" }}>
          <Grid
            lg={12}
            md={12}
            item
            sx={{
              display: { lg: "flex", md: "flex", sm: "flex", xs: "none" },
              background: "#141b22",
              justifyContent: "space-evenly",
              mt: "3.5em",
              mb: "3.5em",
            }}
          >
            <Box
              sx={{
                display: "flex",
                background: "#141b22",
                width: "auto",
              }}
            >
              <img
                style={{
                  width: 240,
                  height: 350,
                }}
                src={img1}
                alt=""
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  ml: 3,
                  mr: 3,
                }}
              >
                <p style={{ color: "white", fontSize: "1.2rem", margin: 0 }}>
                  <strong>
                    Jeen-yuhs:<br></br> A Kanye West Trilogy
                  </strong>
                </p>
                <h4
                  style={{
                    color: "white",
                    fontSize: "1rem",
                    marginBottom: ".5em",
                    marginTop: ".5em",
                  }}
                >
                  Credit: Song Writer
                </h4>
                <Divider sx={{ background: "white" }}></Divider>
              </Box>
            </Box>
            <Box
              sx={{
                display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
                background: "#141b22",
                height: 300,
                width: "auto",
              }}
            >
              <img
                style={{
                  width: 240,
                  height: 350,
                }}
                src={img2}
                alt=""
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  ml: 3,
                  mr: 3,
                }}
              >
                <p style={{ color: "white", fontSize: "1.2rem", margin: 0 }}>
                  <strong>Mindhunter</strong>
                </p>
                <h4
                  style={{
                    color: "white",
                    fontSize: "1rem",
                    marginBottom: ".5em",
                    marginTop: ".5em",
                  }}
                >
                  Credit: Song Writer
                </h4>
                <Divider sx={{ background: "white" }}></Divider>
              </Box>
            </Box>
            <Box
              sx={{
                display: { lg: "flex", md: "none", sm: "none", xs: "none" },
                background: "#141b22",
                height: 300,
                width: "auto",
              }}
            >
              <img
                style={{
                  width: 220,
                  height: 350,
                }}
                src={img3}
                alt=""
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  ml: 3,
                  mr: 3,
                }}
              >
                <p style={{ color: "white", fontSize: "1.2rem", margin: 0 }}>
                  <strong>
                    Welcome To <br></br> Chippendales
                  </strong>
                </p>
                <h4
                  style={{
                    color: "white",
                    fontSize: "1rem",
                    marginBottom: ".5em",
                    marginTop: ".5em",
                  }}
                >
                  Credit: Song Writer{" "}
                </h4>
                <Divider sx={{ background: "white" }}></Divider>
              </Box>
            </Box>
          </Grid>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            mt: "2.5em",
            ml: "1.8em",
          }}
        >
          <Typography>See All </Typography>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon sx={{ fontSize: 30 }} />
          </ExpandMore>
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <FilmCatalog />
        </Collapse>
      </Card>
      <Container>
        <Divider sx={{ background: "white" }}></Divider>
      </Container>
    </div>
  );
};

export default AllFilmSection;
