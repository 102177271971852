import * as React from "react";
import { Card, Container, Box, Divider, Grid, Typography } from "@mui/material";
import img1 from "./../../images/McKnightMed.jpg";
import img2 from "./../../images/GinoLILA.jpg";
import img3 from "./../../images/MacyGMed.jpg";

const AllVideoSection = () => {
  return (
    <div style={{ background: "#141b22" }}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          background: "#141b22",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ color: "white", mt: "1em", fontSize: "2.2rem" }}>
            <strong style={{ justifyContent: "center" }}>
              {" "}
              Video Production <br></br>and Editing
            </strong>{" "}
          </Typography>
        </Box>

        {/* Picture for Xs - */}
        <Box
          sx={{
            display: { lg: "none", md: "none", sm: "none", xs: "flex" },
            justifyContent: "center",
            mt: "2em",
          }}
        >
          <img
            style={{
              width: 220,
              height: 300,
            }}
            src={img2}
            alt=""
          />
        </Box>
      </Grid>
      <Card variant="basic" sx={{ background: "#141b22" }}>
        <Box sx={{ mt: "3em" }}>
          <Grid
            lg={12}
            md={12}
            item
            sx={{
              display: { lg: "flex", md: "flex", sm: "flex", xs: "none" },
              background: "#141b22",
              justifyContent: "space-evenly",
              mt: "3.5em",
              mb: "3.5em",
            }}
          >
            <Box
              sx={{
                display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
                background: "#141b22",
                height: 300,
                width: "auto",
              }}
            >
              <img
                style={{
                  width: 220,
                  height: 300,
                }}
                src={img1}
                alt=""
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  ml: 3,
                  mr: 3,
                }}
              >
                <p style={{ color: "white", fontSize: "1.2rem", margin: 0 }}>
                  <strong>
                    An Evening<br></br> With Brian McKnight
                  </strong>
                </p>
                <h4
                  style={{
                    color: "white",
                    fontSize: "1rem",
                    marginBottom: ".5em",
                    marginTop: ".5em",
                  }}
                >
                  Credit: Editor
                </h4>
                <Divider sx={{ background: "white" }}></Divider>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                background: "#141b22",
                height: 300,
                width: "auto",
              }}
            >
              <img
                style={{
                  width: 220,
                  height: 300,
                }}
                src={img2}
                alt=""
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  ml: 3,
                  mr: 3,
                }}
              >
                <p style={{ color: "white", fontSize: "1.2rem", margin: 0 }}>
                  <strong>
                    Gino Vannelli: <br></br> Live In LA
                  </strong>
                </p>
                <h4
                  style={{
                    color: "white",
                    fontSize: "1rem",
                    marginBottom: ".5em",
                    marginTop: ".5em",
                  }}
                >
                  Credit: Producer
                </h4>
                <Divider sx={{ background: "white" }}></Divider>
              </Box>
            </Box>
            <Box
              sx={{
                display: { lg: "flex", md: "none", sm: "none", xs: "none" },
                background: "#141b22",
                height: 300,
                width: "auto",
              }}
            >
              <img
                style={{
                  width: 220,
                  height: 300,
                }}
                src={img3}
                alt=""
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  ml: 3,
                  mr: 8,
                }}
              >
                <p style={{ color: "white", fontSize: "1.2rem", margin: 0 }}>
                  <strong>
                    Macy Gray - <br></br> The Hits
                  </strong>
                </p>
                <h4
                  style={{
                    color: "white",
                    fontSize: "1rem",
                    marginBottom: ".5em",
                    marginTop: ".5em",
                  }}
                >
                  Credit: Editor{" "}
                </h4>
                <Divider sx={{ background: "white" }}></Divider>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Card>
    </div>
  );
};

export default AllVideoSection;
