import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";

function createData(name, media, credit) {
  return { name, media, credit };
}

const rows = [
  createData("Welcome To Chippendales (2022)", "Hulu Series", "Songwriter"),
  createData("Jeen-yuhs: A Kanye Trilogy (2020)", "Netflix Doc.", "Songwriter"),
  createData("Paris Was a Woman (2020)", "Short", "Composer"),
  createData("Mind Hunter (2017)", "Netflix Series", "Songwriter"),
  createData("Refugee (2010)", "Film", "Re-recording Mixer"),
  createData("The Traveler (2010)", "Film", "Arranger / Producer / Songwriter"),
  createData("A Fix (2008)", "Short", "Composer"),
  createData("Sleepover Nightmare (2005)", "Video", "Composer"),
  createData("Children On Their Birthdays (2002)", "Film", "Composer"),
  createData("Touched By A Killer (2001)", "Film", "Composer"),
  createData("The Void (2001)", "Video", "Orchestrator"),
  createData("Perilous (2000)", "TV Movie", "Composer"),
  createData("The Art of Murder (1999)", "Film", "Composer"),
  createData("SnowBoard Academy (1997)", "Video", "Composer"),
  createData("Wounded (1997) ", "Film", "Music Mixer / Orchestrator"),
  createData("The Final Cut (1995)", "Film", "Arranger"),
  createData("Secrets of the Unknown (1991)", "TV Movie", "Composer"),
  createData("Visitors from the Unknown (1991)", "TV Movie", "Composer"),
  createData("Punk Vacation (1990)", "Video", "Composer"),
  createData("Born To Race (1998)", "Film", "Composer"),
  createData("Impulse (1990)", "Film", "Songwriter"),
  createData("Speed Zone (1989)", "Film", "Songwriter"),
  createData("Tequila Sunrise (1988)", "Film", "Soundtrack / Songwriter "),
  createData("Stakeout (1986)", "Film", "Performer / Songwriter"),
  createData("Ricky 1 (1986)", "Film", "Performer / Producer / Songwriter"),
  createData("Ghost Fever (1986)", "Film", "Performer / Producer / Songwriter"),
  createData("Howard Hewett: Stay (1986)", "Music Video Short", "Composer"),
  createData(
    "The Panther Squad (1984)",
    "Film",
    "Arranger / Producer / Songwriter"
  ),
  createData("Gino Vannelli: Nightwalker (1981)", "Music Video", "Producer"),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "black",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const FilmCatalog = () => {
  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="customized table">
        <TableHead
          sx={{
            background: "white",
          }}
        >
          <TableRow>
            <StyledTableCell align="center">Name / Date </StyledTableCell>
            <StyledTableCell align="center">Media</StyledTableCell>
            <StyledTableCell align="center">Credit</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow
              key={row.name}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <StyledTableCell align="center" component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="center">{row.media}</StyledTableCell>
              <StyledTableCell align="center">{row.credit}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FilmCatalog;
