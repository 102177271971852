import React from "react";
import "./news.css";
import img1 from "./../../images/WTCCover.jpg";
import img3 from "./../../images/JeffPikePromo.jpg";
import img4 from "./../../images/AGameNew.jpg";
import img5 from "./../../images/PikeKeepOnWalkingPromo.jpg";
import img6 from "./../../images/AGAMESINGLEPROMO.jpg";
import img7 from "./../../images/HOOTDPromo.jpg";
import img8 from "./../../images/SOLAPROMO.jpg";
import img9 from "./../../images/TheJokersWildPromo.jpg";
import img10 from "./../../images/SallyPromo.jpg";

import SMStackSmall from "../SMStack/SMStackSmall";
import { Button, Container, Divider, Box, Grid } from "@mui/material";

const newsHeroMobile = () => {
  return (
    <div className="newsHero">
      <Grid container>
        <Grid
          sx={{
            display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },
            justifyContent: "space-evenly",
            mt: "3em",
          }}
          container
        >
          <Grid item xl={12} lg={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                mb: "2em",
              }}
            >
              <img
                style={{
                  height: 425,
                  width: 240,
                  borderRadius: "0px",
                }}
                src={img10}
                alt="Sally (She Says The Sweetest Things) Promo"
              />
              <Button
                href="https://distrokid.com/hyperfollow/jeffpike/sally-she-says-the-sweetest-things"
                aria-label=""
                rel="noopener"
                target="_blank"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "250px",
                  height: "50px",
                  border: "solid 3px",
                  borderColor: "#F3BE12",
                  mt: "2em",
                  "&:hover": {
                    cursor: "pointer",
                    background: "#F3BE12",
                  },
                }}
              >
                <h3 className="buttonText">Listen Now</h3>
              </Button>
              <Button
                href="https://www.youtube.com/watch?v=cxWODRpIuUU&feature=youtu.be"
                aria-label="youtube"
                rel="noopener"
                target="_blank"
                sx={{
                  color: "#FF0000",
                  fontSize: 50,
                  "&:hover": {
                    cursor: "pointer",
                    color: "white",
                  },
                }}
              >
                <SMStackSmall />
              </Button>
              <Box sx={{ width: "250px", textAlign: "center" }}>
                <p className="newsInfo">
                  <b>"Sally (She Says the Sweetest Things)" </b> is Jeff Pike's new single, set to be released on January 17th, 2025
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={12} lg={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                mb: "2em",
              }}
            >
              <img
                style={{
                  height: 425,
                  width: 240,
                  borderRadius: "0px",
                }}
                src={img9}
                alt="Jeff Pike The Jokers Are Wild Promo"
              />
              <Button
                href="https://distrokid.com/hyperfollow/jeffpike/the-jokers-wild-2"
                aria-label=""
                rel="noopener"
                target="_blank"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "250px",
                  height: "50px",
                  border: "solid 3px",
                  borderColor: "#F3BE12",
                  mt: "2em",
                  "&:hover": {
                    cursor: "pointer",
                    background: "#F3BE12",
                  },
                }}
              >
                <h3 className="buttonText">Listen Now</h3>
              </Button>
              <Button
                href="https://youtu.be/8UI_tlkRVjQ"
                aria-label="youtube"
                rel="noopener"
                target="_blank"
                sx={{
                  color: "#FF0000",
                  fontSize: 50,
                  "&:hover": {
                    cursor: "pointer",
                    color: "white",
                  },
                }}
              >
                <SMStackSmall />
              </Button>
              <Box sx={{ width: "250px", textAlign: "center" }}>
                <p className="newsInfo">
                  <b>"The Jokers Wild"</b> is the fourth single release from
                  Jeff Pike's upcoming album entitled "Right Where I Am"
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={12} lg={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                mb: "2em",
              }}
            >
              <img
                style={{
                  height: 425,
                  width: 240,
                  borderRadius: "0px",
                }}
                src={img7}
                alt="Having One of Those Days Promo"
              />
              <Button
                href="https://distrokid.com/hyperfollow/jamiemcroberts/having-one-of-those-days"
                aria-label=""
                rel="noopener"
                target="_blank"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "250px",
                  height: "50px",
                  border: "solid 3px",
                  borderColor: "#F3BE12",
                  mt: "2em",
                  "&:hover": {
                    cursor: "pointer",
                    background: "#F3BE12",
                  },
                }}
              >
                <h3 className="buttonText">Listen Now</h3>
              </Button>
              <Button
                href="https://www.youtube.com/watch?v=C2mNTgiDZdk"
                aria-label="youtube"
                rel="noopener"
                target="_blank"
                sx={{
                  color: "#FF0000",
                  fontSize: 50,
                  "&:hover": {
                    cursor: "pointer",
                    color: "white",
                  },
                }}
              >
                <SMStackSmall />
              </Button>
              <Box sx={{ width: "250px", textAlign: "center" }}>
                <p className="newsInfo">
                  <b>"Having One of Those Days"</b> is the third single released
                  July 28th, 2023
                  {/* Produced by Ross Vannelli. Video directed and edited by
                  Ross Vannelli. */}
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={12} lg={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                mb: "2em",
              }}
            >
              <img
                style={{
                  height: 425,
                  width: 240,
                  borderRadius: "0px",
                }}
                src={img8}
                alt="Jeff Pike (Sunset On L.A) Promo"
              />
              <Button
                href="https://distrokid.com/hyperfollow/jeffpike/sunset-on-la"
                aria-label=""
                rel="noopener"
                target="_blank"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "250px",
                  height: "50px",
                  border: "solid 3px",
                  borderColor: "#F3BE12",
                  mt: "2em",
                  "&:hover": {
                    cursor: "pointer",
                    background: "#F3BE12",
                  },
                }}
              >
                <h3 className="buttonText">Listen Now</h3>
              </Button>
              <Button
                href="https://youtu.be/Rs7oLPGCrI8"
                aria-label="youtube"
                rel="noopener"
                target="_blank"
                sx={{
                  color: "#FF0000",
                  fontSize: 50,
                  "&:hover": {
                    cursor: "pointer",
                    color: "white",
                  },
                }}
              >
                <SMStackSmall />
              </Button>
              <Box sx={{ width: "250px", textAlign: "center" }}>
                <p className="newsInfo">
                  <b>"Sunset On L.A"</b> is the third single release from Jeff
                  Pike’s upcoming album entitled “Right Where I Am”
                  {/* Produced
                  and arranged by Gino Vannelli. Video directed and edited by
                  Ross Vannelli. Release date August 25th, 2023 */}
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={12} lg={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                mb: "2em",
              }}
            >
              <img
                style={{
                  height: 425,
                  width: 240,
                  borderRadius: "0px",
                }}
                src={img6}
                alt="Bring Me Your A Game Single Promo"
              />
              <Button
                href="https://distrokid.com/hyperfollow/jamiemcroberts/bring-me-your-a-game-3 "
                aria-label=""
                rel="noopener"
                target="_blank"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "250px",
                  height: "50px",
                  border: "solid 3px",
                  borderColor: "#F3BE12",
                  mt: "2em",
                  "&:hover": {
                    cursor: "pointer",
                    background: "#F3BE12",
                  },
                }}
              >
                <h3 className="buttonText">Listen Now</h3>
              </Button>
              <Button
                href="https://www.youtube.com/watch?v=ELiv1J8NeAg"
                aria-label="youtube"
                rel="noopener"
                target="_blank"
                sx={{
                  color: "#FF0000",
                  fontSize: 50,
                  "&:hover": {
                    cursor: "pointer",
                    color: "white",
                  },
                }}
              >
                <SMStackSmall />
              </Button>
              <Box sx={{ width: "250px", textAlign: "center" }}>
                <p className="newsInfo">
                  <b>"Bring Me Your 'A' Game" </b> is Jamie McRoberts first ever
                  album, released on May 26th, 2023
                  {/* Produced by Ross
                  Vannelli. Jamie and Ross penned eleven original tunes for her
                  debut album. */}
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={12} lg={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                mb: "2em",
              }}
            >
              <img
                style={{
                  height: 425,
                  width: 240,
                  borderRadius: "0px",
                }}
                src={img4}
                alt="Jamie McRoberts Bring Me Your 'A Game' Promo"
              />
              <Button
                href=" https://distrokid.com/hyperfollow/jamiemcroberts/bring-me-your-a-game-2"
                aria-label=""
                rel="noopener"
                target="_blank"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "250px",
                  height: "50px",
                  border: "solid 3px",
                  borderColor: "#F3BE12",
                  mt: "2em",
                  "&:hover": {
                    cursor: "pointer",
                    background: "#F3BE12",
                  },
                }}
              >
                <h3 className="buttonText">Listen Now</h3>
              </Button>
              <Button
                href=" https://youtu.be/uvjTDAUfVvA"
                aria-label="youtube"
                rel="noopener"
                target="_blank"
                sx={{
                  color: "#FF0000",
                  fontSize: 50,
                  "&:hover": {
                    cursor: "pointer",
                    color: "white",
                  },
                }}
              >
                <SMStackSmall />
              </Button>
              <Box sx={{ width: "250px", textAlign: "center" }}>
                <p className="newsInfo">
                  <b>"Bring Me Your 'A' Game"</b> is the second single from
                  Jamie McRobert's upcoming album, released June 16th, 2023
                  {/* Produced by Ross Vannelli. Video directed and edited by Ross
                  Vannelli. */}
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={12} lg={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                mb: "2em",
              }}
            >
              <img
                style={{
                  height: 425,
                  width: 240,
                  borderRadius: "0px",
                }}
                src={img5}
                alt="Jeff Pike (Right Where I Am) Promo"
              />
              <Button
                href="https://distrokid.com/hyperfollow/jeffpike/keep-on-walking "
                aria-label=""
                rel="noopener"
                target="_blank"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "250px",
                  height: "50px",
                  border: "solid 3px",
                  borderColor: "#F3BE12",
                  mt: "2em",
                  "&:hover": {
                    cursor: "pointer",
                    background: "#F3BE12",
                  },
                }}
              >
                <h3 className="buttonText">Listen Now</h3>
              </Button>
              <Button
                href="https://www.youtube.com/watch?v=fsNl4UjQZEo"
                aria-label="youtube"
                rel="noopener"
                target="_blank"
                sx={{
                  color: "#FF0000",
                  fontSize: 50,
                  "&:hover": {
                    cursor: "pointer",
                    color: "white",
                  },
                }}
              >
                <SMStackSmall />
              </Button>
              <Box sx={{ width: "250px", textAlign: "center" }}>
                <p className="newsInfo">
                  <b>"Keep On Walking"</b> is the second single release from
                  Jeff Pike’s upcoming album entitled “Right Where I Am”
                  {/* Produced and arranged by Gino Vannelli. Video directed and
                  edited by Ross Vannelli. Release date June 2nd, 2023 */}
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={12} lg={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                mb: "2em",
              }}
            >
              <img
                style={{
                  height: 425,
                  width: 240,
                  borderRadius: "0px",
                }}
                src={img3}
                alt="Jeff Pike (Right Where I Am) Promo"
              />
              <Button
                href="https://distrokid.com/hyperfollow/jeffpike/right-where-i-am-2 "
                aria-label=""
                rel="noopener"
                target="_blank"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "250px",
                  height: "50px",
                  border: "solid 3px",
                  borderColor: "#F3BE12",
                  mt: "2em",
                  "&:hover": {
                    cursor: "pointer",
                    background: "#F3BE12",
                  },
                }}
              >
                <h3 className="buttonText">Listen Now</h3>
              </Button>
              <Button
                href="https://youtu.be/8SBj6DRjMlU"
                aria-label="youtube"
                rel="noopener"
                target="_blank"
                sx={{
                  color: "#FF0000",
                  fontSize: 50,
                  "&:hover": {
                    cursor: "pointer",
                    color: "white",
                  },
                }}
              >
                <SMStackSmall />
              </Button>
              <Box sx={{ width: "250px", textAlign: "center" }}>
                <p className="newsInfo">
                  <b>"Right Where I Am"</b> is the first single release from
                  Jeff Pike’s upcoming album entitled “Right Where I Am”
                  {/* Produced and arranged by Gino Vannelli. Video directed and
                  edited by Ross Vannelli. Release date February 10, 2023 */}
                </p>
              </Box>
            </Box>
          </Grid>
          {/* <Grid item xl={12} lg={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                mb: "2em",
              }}
            >
              <img
                style={{
                  height: 450,
                  width: 250,
                  borderRadius: "0px",
                }}
                src={img1}
                alt="Welcome to Chippendales Promo"
              />
              <Button
                href="https://distrokid.com/hyperfollow/rossvannelli/room-service"
                aria-label=""
                rel="noopener"
                target="_blank"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "250px",
                  border: "solid 4px",
                  borderColor: "#F3BE12",
                  mt: "2em",
                  "&:hover": {
                    cursor: "pointer",
                    background: "#F3BE12",
                  },
                }}
              >
                <h3 className="buttonText">Listen Now</h3>
              </Button>
              <Button
                href="https://www.youtube.com/watch?v=DchtacgVLbQ"
                aria-label="youtube"
                rel="noopener"
                target="_blank"
                sx={{
                  color: "#FF0000",
                  fontSize: 50,
                  "&:hover": {
                    cursor: "pointer",
                    color: "white",
                  },
                }}
              >
                <SMStackSmall />
              </Button>
              <Box sx={{ width: "350px", textAlign: "center" }}>
                <p className="newsInfo">
                  In episode 3 of the brand new exclusive{" "}
                  <b>Hulu series "Welcome to Chippendales"</b>, Ross's song{" "}
                  <b>"Room Service"</b> is extensively featured.
                </p>
              </Box>
            </Box>
          </Grid> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default newsHeroMobile;
