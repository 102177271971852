import * as React from "react";
import Stack from "@mui/material/Stack";
import { Icon } from "@iconify/react";

const SMStack = () => {
  return (
    <Stack direction="row" spacing={3} sx={{ fontSize: 40 }}>
      <Icon icon="bi:youtube" />
      <Icon icon="bi:spotify" />
      <Icon icon="bi:apple" />
    </Stack>
  );
};

export default SMStack;
