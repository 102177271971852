import React from "react";
import NavigationBar from "../components/Grids/NavigationBar";
import NavigationBarMobile from "../components/Grids/NavigationBarMobile";
import ContactMeHero from "./../components/Grids/ContactMeHero";
import GalleryContent from "../components/Grids/GalleryContent";
import { Box, Button } from "@mui/material";

const GalleryPage = () => {
  return (
    <div style={{ background: "#141b22" }}>
      <NavigationBarMobile />
      <NavigationBar />
      <GalleryContent />
      <Box sx={{ position: "fixed", bottom: 0, right: 0, left: 0 }}>
        <ContactMeHero />
      </Box>
    </div>
  );
};

export default GalleryPage;
