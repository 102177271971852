import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import img1 from "./../../images/SmallAlbum/B2BAlbum.jpg";
import img2 from "./../../images/SmallAlbum/Take6Album.jpg";
import img3 from "./../../images/SmallAlbum/Annuhea.jpg";
import img4 from "./../../images/SmallAlbum/BigDreamers.jpg";
import img5 from "./../../images/SmallAlbum/BlackCars.jpg";
import img6 from "./../../images/SmallAlbum/FeelsGood.jpg";
import img7 from "./../../images/SmallAlbum/ICommitToLove.jpg";
import img8 from "./../../images/SmallAlbum/ItsTime.jpg";
import img9 from "./../../images/SmallAlbum/OneLoveOneDream.jpg";
import img10 from "./../../images/SmallAlbum/RaisinsChristmasWatch.jpg";
import img11 from "./../../images/SmallAlbum/RaisinsSweet.jpg";
import img12 from "./../../images/SmallAlbum/Nightwalker.jpg";
import img13 from "./../../images/SmallAlbum/CollegeDropoutSmall.jpg";
import img14 from "./../../images/SmallAlbum/Faces.jpg";
import img15 from "./../../images/SmallAlbum/TallDarkandHandsome.jpg";
import img16 from "./../../images/albums/AlbumCover.jpg";
import img17 from "./../../images/SmallAlbum/Alimorad.jpg";
import img18 from "./../../images/SmallAlbum/TequilaSunrise.jpg";
import img19 from "./../../images/SmallAlbum/Children.jpeg";
import img20 from "./../../images/SmallAlbum/Stakeout.jpg";
import img21 from "./../../images/SmallAlbum/RaisinsTheHits.jpg";
import img22 from "./../../images/SmallAlbum/30thAnniversary.jpg";
import img23 from "./../../images/SmallAlbum/ComeInto.jpg";

function createData(art, album, songName, credit) {
  return { art, album, songName, credit };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "black",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const rows = [
  createData(
    <img
      style={{
        width: 80,
        height: 80,
      }}
      src={img17}
      alt=""
    />,
    <strong>In Progress - Jamie Alimorad (2022)</strong>,
    "",
    " Producer"
  ),
  createData("", "", "Give A Little Lovin", "Music / Lyrics"),
  createData("", "", "Break The Fall", "Music / Lyrics"),
  createData("", "", "It Never Rains", "Music / Lyrics"),

  createData(
    <img
      style={{
        width: 80,
        height: 80,
      }}
      src={img16}
      alt=""
    />,
    <strong>"Give Me Your 'A' Game" - Jamie McRoberts (2022)</strong>,
    "",
    " Producer / Arranger"
  ),
  createData("", "", "Give Me Your 'A' Game", "Music / Lyrics"),
  createData("", "", "What Are You Looking For", "Music / Lyrics"),
  createData("", "", "She Has It Coming", "Music / Lyrics"),
  createData("", "", "Having One Of Those Day", "Music / Lyrics"),
  createData("", "", "Just Like I remember", "Music / Lyrics"),
  createData("", "", "Nothing Last Forever", "Music / Lyrics"),
  createData("", "", "RIDE", "Music / Lyrics"),
  createData("", "", "Time Won't Let Me", "Music / Lyrics"),
  createData("", "", "Get What You Want", "Music / Lyrics"),
  createData("", "", "Waiting for the Sky to Fall", "Music / Lyrics"),
  createData("", "", "Olds Highway Road", "Music / Lyrics"),

  createData(
    <img
      style={{
        width: 80,
        height: 80,
      }}
      src={img3}
      alt=""
    />,
    <strong>"Follow Me - Annuhea (2017)"</strong>,
    "",
    " Producer"
  ),
  createData("", "", "Make It Look Easy", "Songwriter"),
  createData("", "", "Like The Way It Feels", "Songwriter"),
  createData("", "", "Riddles", "Songwriter"),
  createData("", "", "Right Now", "Songwriter"),
  createData("", "", "I Won’t Settle", "Songwriter"),
  createData("", "", "Hands Up", "Songwriter"),
  createData("", "", "Right Back Where I Started", "Songwriter"),
  createData("", "", "X Lover", "Songwriter"),
  createData("", "", "Don’t Want Your Money ft. mahi", "Songwriter"),
  createData("", "", "Who Knew", "Songwriter"),
  createData("", "", "How Not To Hurt You", "Songwriter"),
  createData("", "", "Follow Me", "Songwriter"),
  createData("", "", "Walls", "Songwriter"),
  createData("", "", "Mixed Feelings ft. Sammy Johnson", "Songwriter"),

  createData(
    <img
      style={{
        width: 80,
        height: 80,
      }}
      src={img2}
      alt=""
    />,
    <strong>"Believe - Take 6 (2016)"</strong>,
    "",
    " Producer "
  ),
  createData("", "", "Beautiful Day", "Songwriter"),
  createData("", "", "Reset", "Songwriter"),
  createData("", "", "You Know You’re In Love", "Songwriter"),
  createData("", "", "When Angels Cry", "Songwriter"),
  createData("", "", "Here In L.A.", "Songwriter"),
  createData("", "", "Walk Away", "Songwriter"),
  createData("", "", "Statistic", "Songwriter"),
  createData("", "", "You Make Me Happy", "Songwriter"),
  createData("", "", "On Your Side", "Songwriter"),
  createData("", "", "Keep The Faith", "Songwriter"),
  createData("", "", "You’re All I Need", "Songwriter"),
  createData("", "", "Song For You", "Songwriter"),
  createData("", "", "5 Minutes With God", "Songwriter"),
  createData("", "", "When Angels Cry (acapella)", "Songwriter"),

  createData(
    <img
      style={{
        width: 80,
        height: 80,
      }}
      src={img13}
      alt=""
    />,
    <strong>"College Dropout" - Kanye West (2004)</strong>,
    "",
    " Song Writer"
  ),
  createData("", "", "We Don't Care", "Songwriter"),

  createData(
    <img
      style={{
        width: 80,
        height: 80,
      }}
      src={img6}
      alt=""
    />,
    <strong>"Feels Good" - Glenn Jones (2002)</strong>,
    "",
    " Producer"
  ),
  createData("", "", "I Wonder Why", "Songwriter"),
  createData("", "", "Girl In The Corner", "Songwriter"),
  createData("", "", "When Love Breaks U Down", "Songwriter"),

  createData(
    <img
      style={{
        width: 80,
        height: 80,
      }}
      src={img8}
      alt=""
    />,
    <strong>"I Think It's Time" - Glenn Jones (1998)</strong>,
    "",
    " Producer / Arranger"
  ),
  createData("", "", "Here All the Time", "Songwriter"),
  createData("", "", "I Think It’s Time", "Songwriter"),
  createData("", "", "When Were Making Love", "Songwriter"),
  createData("", "", "24/7", "Song writer"),
  createData("", "", "I Won’t Settle", "Songwriter"),
  createData("", "", "Thankful", "Song writer"),
  createData("", "", "I’m On Your Side", "Songwriter"),
  createData("", "", "Let It Rain", "Songwriter"),
  createData("", "", "Secrets", "Songwriter"),

  createData(
    <img
      style={{
        width: 80,
        height: 80,
      }}
      src={img22}
      alt=""
    />,
    <strong>"30th Anniversary Collection - The Hollies (1993)"</strong>,
    "",
    " Co-producer / Arranger"
  ),
  createData("", "", "Nothing Else But Love", "Songwriter"),

  createData(
    <img
      style={{
        width: 80,
        height: 80,
      }}
      src={img9}
      alt=""
    />,
    <strong>"One Love - One Dream" - Jeffrey Osborne (1988)</strong>,
    "",
    " Producer"
  ),
  createData("", "", "Can’t Go Back On A Promise", "Songwriter"),
  createData("", "", "My Heart Can Wait Forever", "Songwriter"),

  createData(
    <img
      style={{
        width: 80,
        height: 80,
      }}
      src={img10}
      alt=""
    />,
    <strong>
      "Christmas with the California Raisins" - California Raisins (1988)
    </strong>,
    "",
    "Producer"
  ),
  createData("", "", "It's Christmas Again", "Songwriter"),

  createData(
    <img
      style={{
        width: 80,
        height: 80,
      }}
      src={img4}
      alt=""
    />,
    <strong>"Big Dreamers Never Sleep - Gino Vannelli (1987)"</strong>,
    "",
    " Co-producer / Arranger"
  ),
  createData("", "", "In the Name Of Money", "Songwriter"),
  createData("", "", "Time Out", "Songwriter"),

  createData(
    <img
      style={{
        width: 80,
        height: 80,
      }}
      src={img21}
      alt=""
    />,
    <strong>"Sing the Hit Songs" - California Raisins (1987)</strong>,
    "",
    "Producer"
  ),
  createData("", "", "Sweet Delicious and Marvelous", "Songwriter"),

  createData(
    <img
      style={{
        width: 80,
        height: 80,
      }}
      src={img11}
      alt=""
    />,
    <strong>
      "Sweet, Delicious and Marvelous" - California Raisins (1987)
    </strong>,
    "",
    "Producer"
  ),
  createData("", "", "You Don't Have To Wait", "Songwriter"),

  createData(
    <img
      style={{
        width: 80,
        height: 120,
      }}
      src={img15}
      alt=""
    />,
    <strong>"Tall Dark and Handsome" - Chippendales (1987)</strong>,
    "",
    " Producer"
  ),
  createData("", "", "Off The Cuff", "Songwriter"),
  createData("", "", "Room Service", "Songwriter"),
  createData("", "", "Magnetic Love", "Songwriter"),
  createData("", "", "Passion Under The Moon", "Songwriter"),
  createData("", "", "More Than A Photograph", "Songwriter"),
  createData("", "", "Hot To The Touch", "Songwriter"),
  createData("", "", "Private Affair", "Songwriter"),
  createData("", "", "Welcome To My Fantasy", "Songwriter"),

  createData(
    <img
      style={{
        width: 80,
        height: 80,
      }}
      src={img7}
      alt=""
    />,
    <strong>"I Commit To Love" - Howard Hewett (1986)</strong>,
    "",
    " Co-producer / Arranger"
  ),
  createData("", "", "Last Forever", "Songwriter"),
  createData("", "", "Stay", "Songwriter"),

  createData(
    <img
      style={{
        width: 80,
        height: 80,
      }}
      src={img5}
      alt=""
    />,
    <strong>"Black Cars - Gino Vannelli (1984)"</strong>,
    "",
    " Co-producer / Arranger"
  ),
  createData("", "", "It's Over", "Songwriter"),
  createData("", "", "Just A Motion Away", "Songwriter"),

  createData(
    <img
      style={{
        width: 80,
        height: 80,
      }}
      src={img12}
      alt=""
    />,
    <strong>"Nightwalker - Gino Vannelli (1981)"</strong>,
    "",
    " Co-producer / Arranger"
  ),
  createData("", "", "Seek and You Will Find", "Songwriter"),
  createData("", "", "I Believe", "Songwriter"),

  createData(
    <img
      style={{
        width: 80,
        height: 80,
      }}
      src={img14}
      alt=""
    />,
    <strong>"Faces" - Earth Wind and Fire (1980)</strong>,
    "",
    " Co-producer / Arranger"
  ),
  createData("", "", "You Went Away", "Music and Lyrics"),

  createData(
    <img
      style={{
        width: 80,
        height: 80,
      }}
      src={img23}
      alt=""
    />,
    <strong>"Come Into My World - The Emotions (1979)"</strong>,
    "",
    " Co-producer / Arranger"
  ),
  createData("", "", "Where is Your Love?", "Songwriter"),
  createData("", "", "Yes I Am", "Songwriter"),

  createData(
    <img
      style={{
        width: 80,
        height: 80,
      }}
      src={img1}
      alt=""
    />,
    <strong>"Brother to Brother - Gino Vannelli (1978)"</strong>,
    "",
    " Co-producer / Arranger"
  ),
  createData("", "", "I Just Wanna Stop", "Songwriter"),
  createData("", "", "Love and Emotion", "Songwriter"),

  createData(
    <img
      style={{
        width: 80,
        height: 120,
      }}
      src={img19}
      alt=""
    />,
    <strong>"Children On Their Birthdays" (theme) (2002) </strong>,
    "I Have To Dream",
    "SongWriter"
  ),

  createData(
    <img
      style={{
        width: 80,
        height: 120,
      }}
      src={img18}
      alt=""
    />,
    <strong>"Tequila Sunrise" (theme) (1988) </strong>,
    "Surrender To Me",
    "SongWriter"
  ),

  createData(
    <img
      style={{
        width: 100,
        height: 70,
      }}
      src={img20}
      alt=""
    />,
    <strong>"Stakeout" (1987) </strong>,
    "Desire To Win",
    "SongWriter"
  ),
];

const MusicCatalog = () => {
  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="customized table">
        <TableHead
          sx={{
            background: "white",
          }}
        >
          <TableRow>
            <StyledTableCell align="center">Art </StyledTableCell>
            <StyledTableCell align="center">Album Name </StyledTableCell>
            <StyledTableCell align="center">Song Name</StyledTableCell>
            <StyledTableCell align="center">Credit</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow
              key={row.name}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                // background: "#141b22",
              }}
            >
              <StyledTableCell align="center" component="th" scope="row">
                {row.art}
              </StyledTableCell>
              <StyledTableCell align="center">{row.album}</StyledTableCell>
              <StyledTableCell align="center">{row.songName}</StyledTableCell>
              <StyledTableCell align="center">{row.credit}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MusicCatalog;
