import React from "react";
import "./tourDates.css";
import img1 from "./../../images/GinoMgmt3.jpg";
import { Button, Container, Divider, Box, Grid } from "@mui/material";

const tourDates = () => {
  return (
    <div className="tourHero">
      <Container>
        <Divider
          sx={{
            display: { lg: "flex", md: "flex", sm: "flex", xs: "flex" },
            background: "white",
          }}
        ></Divider>
      </Container>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: "3em",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
            justifyContent: "center",
          }}
        >
          <h1 className="tourTitle">Gino Vannelli - Tour Dates</h1>
        </Box>
        <Box
          sx={{
            display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
            justifyContent: "center",
          }}
        >
          <h1
            className="tourTitleMobile"
            style={{
              display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
              justifyContent: "center",
            }}
          >
            Gino Vannelli <br></br> Tour Dates
          </h1>
        </Box>
      </Box>

      <Box
        sx={{
          display: { lg: "flex", md: "none", sm: "none", xs: "none" },
          paddingBottom: "3em",
          paddingTop: "1em",
          justifyContent: "center",
        }}
      >
        <Button
          href="https://ginov.com/tour/"
          aria-label="Tickets"
          rel="noopener"
          target="_blank"
          sx={{
            display: "flex",
            width: "200px",
            height: "40px",
            border: "solid 3px",
            borderColor: "#F3BE12",
            "&:hover": {
              cursor: "pointer",
              background: "#F3BE12",
            },
          }}
        >
          <h3 className="buttonTextTour">See All Dates</h3>
        </Button>
      </Box>

      {/* Tour Dates for Sm+ */}

      <Box
        sx={{
          display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },
          paddingBottom: "2em",
          paddingTop: "1em",
          justifyContent: "center",
        }}
      >
        <Button
          href="https://ginov.com/tour/"
          aria-label="Tickets"
          rel="noopener"
          target="_blank"
          sx={{
            display: "flex",
            width: "200px",
            height: "40px",
            border: "solid 3px",
            borderColor: "#F3BE12",
            "&:hover": {
              cursor: "pointer",
              background: "#F3BE12",
            },
          }}
        >
          <h3 className="buttonTextTour">See All Dates</h3>
        </Button>
      </Box>
    </div>
  );
};

export default tourDates;

{
  /* <Grid
        sx={{
          display: { lg: "flex", md: "none", sm: "none", xs: "none" },
          justifyContent: "space-evenly",
          mt: "3em",
          mb: "5em",
        }}
        container
      >
        <Grid item xl={12} lg={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <img
              style={{
                height: 250,
                width: 450,
                borderRadius: "10px",
              }}
              src={img1}
              alt="Gino Vannelli Tour Photo"
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                ml: "5em",
              }}
            >
              <Box>
                <p className="tourInfo">
                  Thu, SEP 21, 2023 | <a className="location">Arcada Theatre</a>{" "}
                  | St Charles, IL
                </p>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Button
                  href="https://www.bandsintown.com/artist-rsvp/109748?event_id=1027695282&utm_campaign=rsvp&utm_medium=web&app_id=js_ginov.com&affil_code=js_ginov.com&utm_source=widget&came_from=242&spn=0&signature=ZZ51e6e850100aaac47d2a4f142c275f3edb8e2102bac807b38cca1aacbdc1e3a6"
                  aria-label="Tickets"
                  rel="noopener"
                  target="_blank"
                  sx={{
                    display: "flex",
                    width: "200px",
                    height: "40px",
                    mr: "2em",
                    border: "solid 3px",
                    borderColor: "#F3BE12",
                    "&:hover": {
                      cursor: "pointer",
                      background: "#F3BE12",
                    },
                  }}
                >
                  <h3 className="buttonTextTour">RSVP</h3>
                </Button>
                <Button
                  href="https://www.bandsintown.com/t/1027695282?affil_code=js_ginov.com&app_id=js_ginov.com&came_from=242&utm_campaign=ticket&utm_medium=web&utm_source=widget"
                  aria-label="Tickets"
                  rel="noopener"
                  target="_blank"
                  sx={{
                    display: "flex",
                    width: "200px",
                    height: "40px",
                    border: "solid 3px",
                    borderColor: "#F3BE12",
                    "&:hover": {
                      cursor: "pointer",
                      background: "#F3BE12",
                    },
                  }}
                >
                  <h3 className="buttonTextTour">Tickets</h3>
                </Button>
              </Box>

              <Box sx={{ mt: "1em" }}>
                  <p className="tourInfo">
                    Fri, MAR 3, 2023 | 2023 Bilheimer Capitol Theatre
                  </p>
                </Box>
                <Button
                  href=" https://www.bandsintown.com/e/103963341?affil_code=js_ginov.com&app_id=js_ginov.com&came_from=242&utm_campaign=event&utm_medium=web&utm_source=widget"
                  aria-label="facebook"
                  rel="noopener"
                  target="_blank"
                  sx={{
                    display: "flex",
                    width: "250px",
                    border: "solid 4px",
                    borderColor: "#F3BE12",
                    "&:hover": {
                      cursor: "pointer",
                      background: "#F3BE12",
                    },
                  }}
                >
                  <h3 className="buttonTextTour">Tickets</h3>
                </Button>
            </Box>
          </Box>
        </Grid>
      </Grid> */
}
