import * as React from "react";
import { Divider, Grid, Typography, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SMStackFull from "../SMStack/SMStackFull";
import BSCarousel from "../Carousel/BSCarousel";
import BSCarouselSmall from "../Carousel/BSCarouselSmall";
import "./AboutHero.css";
import img1 from "./../../images/Logo-Brick.png";

const LandingBanner = () => {
  let navigate = useNavigate();
  return (
    <div style={{ background: "#141b22" }}>
      <Grid container sx={{}}>
        {/* About for Lg - */}
        <Grid
          item
          lg={6}
          sx={{
            display: { lg: "flex", md: "none", sm: "none", xs: "none" },
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            background: "#141b22",
          }}
        >
          <h1 className="otherTitle" style={{ marginLeft: "2.3em" }}>
            COA Productions
          </h1>

          <Typography
            sx={{
              fontSize: "1.3rem",
              fontWeight: "bold",
              textAlign: "center",
              width: "65%",
              color: "#918ca4",
              ml: "8em",
            }}
          >
            Label, management, music and video production. Featuring{" "}
            <strong style={{ color: "white" }}>
              Jamie McRoberts, Jeff Pike, Jamie Alimorad and Gino Vannelli
            </strong>
            .
          </Typography>

          <Box sx={{ pt: "1em", ml: "10em" }}>
            <SMStackFull />
          </Box>
        </Grid>

        {/* About for Md - */}
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          sx={{
            display: { lg: "none", md: "flex", sm: "flex", xs: "none" },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background: "#141b22",
          }}
        >
          <h1 className="otherTitle">COA Productions</h1>

          <Typography
            sx={{
              fontSize: "1.3rem",
              fontWeight: "bold",
              textAlign: "center",
              width: "50%",
              color: "#918ca4",
            }}
          >
            Label, management, music and video production. Featuring{" "}
            <strong style={{ color: "white" }}>
              Jamie McRoberts, Jeff Pike, Jamie Alimorad and Gino Vannelli
            </strong>
            .
          </Typography>

          <Box sx={{ pt: "1em" }}>
            <SMStackFull />
          </Box>
        </Grid>

        {/* Picture for Lg + */}
        <Grid
          className="test"
          item
          lg={6}
          sx={{
            display: { lg: "flex", md: "none", sm: "none", xs: "none" },
            justifyContent: "start",
            alignItems: "start",
            background: "#141b22",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <Box sx={{ ml: "3em" }}>
            <BSCarousel></BSCarousel>
          </Box>
        </Grid>

        {/* About for Xs */}
        <Grid
          item
          xs={12}
          sx={{
            display: { lg: "none", md: "none", sm: "none", xs: "flex" },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            background: "#141b22",
            p: "1em",
          }}
        >
          <h1 className="otherTitleMobile">COA Productions</h1>

          <Typography
            sx={{
              fontSize: "1.1rem",
              fontWeight: "bold",
              mt: ".5em",
              mb: "1em",
              pl: "1em",
              pr: "1em",
              color: "#918ca4",
            }}
          >
            Label, management, music and video production. Featuring{" "}
            <strong style={{ color: "white" }}>
              Jamie McRoberts, Jeff Pike, Jamie Alimorad and Gino Vannelli.
            </strong>
          </Typography>
          <SMStackFull />
        </Grid>

        {/* Picture for Md - */}
        <Grid
          item
          md={12}
          sm={12}
          sx={{
            display: { lg: "none", md: "flex", sm: "flex", xs: "none" },
            justifyContent: "center",
            alignItems: "center",
            background: "#141b22",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <Box sx={{ mt: "0" }}>
            <BSCarousel></BSCarousel>
          </Box>
        </Grid>

        {/* Picture for Xs - */}
        <Grid
          item
          xs={12}
          sx={{
            display: { lg: "none", md: "none", sm: "none", xs: "flex" },
            justifyContent: "center",
            alignItems: "center",
            background: "#141b22",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <Box sx={{ mt: "0" }}>
            <BSCarouselSmall></BSCarouselSmall>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default LandingBanner;
