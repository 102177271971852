import * as React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import img1 from "./../../images/Logo-Brick.png";

const NavigationBar = () => {
  let navigate = useNavigate();
  return (
    <div className="navBarHolder" style={{ background: "#141b22" }}>
      <Box
        sx={{
          display: { lg: "flex", md: "flex", sm: "flex", xs: "none" },
          flexDirection: "row",
          color: "white",
          justifyContent: "center",
        }}
      >
        <Box
          onClick={() => navigate("/")}
          sx={{
            display: "flex",
            mr: "3em",

            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <img
            style={{
              width: 70,
              height: 70,
            }}
            src={img1}
            alt=""
          />
        </Box>
        <Button
          onClick={() => navigate("/projects")}
          className="navButton"
          sx={{
            fontSize: "1rem",
            pl: "2em",
            pr: "2em",
            color: "white",
          }}
        >
          Music & Film
        </Button>

        <Button
          onClick={() => navigate("/about")}
          className="navButton"
          sx={{
            fontSize: "1rem",
            pl: "2em",
            pr: "2em",
            color: "white",
          }}
        >
          About
        </Button>

        <Button
          onClick={() => navigate("/management")}
          className="navButton"
          sx={{
            fontSize: "1rem",
            pl: "2em",
            pr: "2em",
            color: "white",
          }}
        >
          Management
        </Button>

        {/* <Button
          onClick={() => navigate("/Gallery")}
          className="navButton"
          sx={{
            fontSize: "1rem",
            pl: "2em",
            pr: "2em",
            color: "white",
          }}
        >
          Gallery
        </Button> */}
      </Box>
    </div>
  );
};

export default NavigationBar;
