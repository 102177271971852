import React from "react";
import AllFilmSection from "../components/Grids/AllFilm";
import AllMusicSection from "../components/Grids/AllMusic";
import AllVideoSection from "../components/Grids/AllVideo";
import NavigationBar from "../components/Grids/NavigationBar";
import NavigationBarMobile from "../components/Grids/NavigationBarMobile";
import ContactMeHero from "./../components/Grids/ContactMeHero";

const ProjectsPage = () => {
  return (
    <div>
      <NavigationBarMobile />
      <NavigationBar />

      <AllMusicSection />
      <AllFilmSection />
      <AllVideoSection />
      <ContactMeHero />
    </div>
  );
};

export default ProjectsPage;
