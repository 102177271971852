import * as React from "react";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import img1 from "./../../images/awards/CollegePlat.jpg";
import img2 from "./../../images/awards/GrammyNom.jpg";
import img3 from "./../../images/awards/DoubleJuno.jpg";
import img4 from "./../../images/RossYoung.jpg";
import img5 from "./../../images/JamieA1.jpg";
import img6 from "./../../images/awards/BrotherPlat.jpg";
import img8 from "./../../images/Movie/TequilaSunrise.jpg";
import img9 from "./../../images/awards/FacesGold.jpg";
import img10 from "./../../images/awards/RaisinPlat.jpg";
import img11 from "./../../images/JamieChart.jpg";

const Biography = () => {
  return (
    <div style={{ background: "white" }}>
      <Container sx={{ background: "white" }}>
        <Grid container sx={{}}>
          {/* Early Life and Career */}
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex" }}>
            <Box>
              <h2
                style={{
                  margin: 0,
                  color: "gray",
                  fontWeight: "bold",
                  fontSize: "1.8rem",
                  lineHeight: 1.235,
                  letterSpacing: "0.00735em",
                  marginTop: "2em",
                }}
              >
                Early Life and Career
              </h2>
              <Divider sx={{ background: "gray", mb: 2 }}></Divider>

              <Typography
                sx={{
                  fontSize: "1.1rem",
                  fontWeight: 400,
                  color: "gray",
                }}
              >
                <Box sx={{ float: "left", pr: 3, pb: 1 }}>
                  <img
                    style={{
                      width: 170,
                      height: 220,
                    }}
                    src={img4}
                    alt=""
                  />
                </Box>
                <strong>Born and raised in Montreal</strong>, Ross began playing
                in bands, arranging, writing and producing at an early age. His
                success started early as he composed, co-arranged and
                co-produced multiple songs and albums for his brother
                <strong> Gino Vannelli </strong>. The smash single,{" "}
                <strong>“I Just Wanna Stop”</strong>, which he wrote and
                co-produced for Gino has been rerecorded and released all over
                the world (over 5 million radio plays). His alliance with
                brothers Gino and Joe netted him many successes including Gino’s
                Platinum record <strong>“Brother to Brother”</strong>, which
                earned Ross a Juno Award for production,{" "}
                <strong>“Black Cars”</strong>,{" "}
                <strong>
                  “Nightwalker”<strong>,</strong> “Big Dreamers Never Sleep”
                </strong>
                , <strong>“Inconsolable Man”</strong>, which earned
                International Gold and Platinum awards, and{" "}
                <strong>“Living Inside Myself”</strong> for which he was
                nominated for a Grammy with his brothers. Working with many
                artists Ross earned a reputation as a top songwriter and
                producer. Ross acquired an extensive list of credits including
                cuts with,{" "}
                <strong>
                  Jeffrey Osborne, The Gap Band, Angela Bofill, Howard Hewett,
                  Kim Carnes, Jennifer Holliday, The Emotions, Richard Marx,
                  Ramsey Lewis, Ann Wilson, Robin Zanders, The Hollies, Earth
                  Wind and Fire, Denise Williams, Lee Ritenour, Buddy Miles,
                  Glenn Jones, Bob James, Lara Fabian, Alexandre Pires, Celine
                  Dion
                </strong>{" "}
                and more. He produced the successful California Raisins series
                which earned Gold and Platinum domestic and International. Ross
                co- produced the bilingual hit single,{" "}
                <strong>Wheels of Life</strong>, a #2 song in Canada, performed
                by <strong>Gino Vannelli</strong> and{" "}
                <strong>Martin St.Clair</strong>.
              </Typography>
            </Box>
          </Grid>
          {/* Continued */}
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex" }}>
            <Box
              sx={{
                mt: "1.5em",
              }}
            >
              <Typography
                sx={{ fontSize: "1.1rem", fontWeight: 400, color: "gray" }}
              >
                <Box sx={{ float: "right", pl: 1.5, pb: 1 }}>
                  <img
                    style={{
                      width: 220,
                      height: 280,
                    }}
                    src={img8}
                    alt=""
                  />
                </Box>
                By 1986 Ross began composing for film. He scored, produced and
                wrote multiple songs featuring various artists for his first
                film project, an <strong>MGM</strong> release entitled,{" "}
                <strong>“Born to Race”</strong>. He continued writing for film
                and co-wrote with Richard Marx,
                <strong>“Surrender to Me”</strong> which became the end title
                theme song for the film <strong>“Tequila Sunrise”</strong>{" "}
                starring{" "}
                <strong>Michelle Pfieffer, Mel Gibson and Kurt Russell</strong>.
                “Surrender to Me” became a hit breaking top ten on the Billboard
                hot 100, earning Ross two <strong>BMI Awards</strong> for most
                performed radio song from a motion picture. “Surrender to me”
                was also rerecorded by Richard Marx and Lara Fabian, and
                released in Canada on Richard Marx’s “Flesh and Bone” album and
                also his Greatest Hits album. Other successes include{" "}
                <strong>“Everybody Needs Someone”</strong>, a theme song written
                for <strong>Kim Carnes</strong> for the Warner Brothers release{" "}
                <strong>“Impulse”</strong> starring Theresa Russell. He also
                composed music for three one-hour <strong>CBS</strong> primetime
                specials entitled <strong>“Visitors From the Unknown”</strong>.
                Ross composed scores for the feature films{" "}
                <strong>“The Final Cut”</strong>, starring{" "}
                <strong>Sam Elliot</strong>, directed by British filmmaker{" "}
                <strong>Roger Christian</strong> (Nostradamus), and “Wounded”
                produced by Keystone Entertainment. Both films were released by{" "}
                <strong>Republic Pictures</strong> and featured on{" "}
                <strong>HBO</strong> as special presentations. Ross also
                composed music for the comedy{" "}
                <strong>“Snowboard Academy”</strong> a 1998{" "}
                <strong>Columbia Tristar </strong>
                video release, starring{" "}
                <strong>
                  Corey Haim, Jim Varney (Ernest), and Brigitte Nielsen
                </strong>{" "}
                . Ross produced and co-wrote eight songs for the album{" "}
                <strong>“It’s Time”</strong> featuring SAR recording artist
                <strong> Glenn Jones</strong>. The first single{" "}
                <strong>“Let It Rain”</strong>
                charted on Billboard’s Hot 100 R&B single chart, and broke top
                10 on the R&R Urban AC Airplay chart throughout America. The
                album “It’s Time” hit Billboard’s top 100 R&B album chart, and
                was licensed by the producers of
                <strong> Dawson’s Creek</strong> and{" "}
                <strong>Party of Five</strong> for their 1999 / 2000 season.
              </Typography>
            </Box>
          </Grid>
          {/* Continued */}
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex" }}>
            <Box
              sx={{
                mt: "1.5em",
              }}
            >
              <Typography
                sx={{ fontSize: "1.1rem", fontWeight: 400, color: "gray" }}
              >
                <Box sx={{ float: "left", pr: 2.5, pb: 1 }}>
                  <img
                    style={{
                      width: 220,
                      height: 280,
                    }}
                    src={img1}
                    alt=""
                  />
                </Box>
                Ross co-wrote and co-produced the theme song{" "}
                <strong>“If you Only Knew”</strong>
                for the film <strong>“If You Only Knew”</strong> starring{" "}
                <strong>Allison Eastwood</strong> and{" "}
                <strong>Jonathan Sheck</strong> shown at Cannes Film Festival,
                and now showing on <strong>HBO</strong>. Ross composed the score
                for four independent films <strong>“The Art of Murder"</strong>,
                starring
                <strong> Michael Moriarty</strong>,{" "}
                <strong>“Touched By A Killer”</strong>, starring{" "}
                <strong>Isabella Hofmann</strong> and{" "}
                <strong>James Wilder</strong>, <strong>“Perilous"</strong>{" "}
                starring <strong>Bruce Boxleitner </strong>and{" "}
                <strong>Catherine Oxenberg</strong>, and a Lion’s Gate Film
                titled <strong>“The Void"</strong> starring{" "}
                <strong>Malcolm Mcdowell</strong>. In 2004 Ross completed music
                for a film titled <strong>“Children On Their Birthdays"</strong>
                , which includes 15 songs written or co-written by Ross,
                including underscore, and final credit song{" "}
                <strong>“I have to Dream" </strong>
                performed by <strong>Celine Dion</strong>. Ross wrote and
                produced three songs for a new <strong>Glenn Jones</strong> CD
                released on Peak Records. Co- produced and co-wrote with Richard
                Marx a song
                <strong>“Back In My Baby’s Arms"</strong> for the Universal
                Danish artist
                <strong>Christina Undhjem</strong>, who has come off two gold
                albums. Ross co-wrote and co-produced with brothers Joe and Gino
                the song <strong>“The Last Dance"</strong> for Gino’s CD{" "}
                <strong>“Canto”</strong>
                on <strong>BMG Records</strong>, released in Canada. Ross’s song
                <strong>“Es Mejor Parar”</strong> performed by BMG Records Latin
                star <strong>Alexandre Pires</strong>, hit the number 12 spot on
                the Billboard Latin Charts. Ross co-wrote the song{" "}
                <strong>“We Don’t Care”</strong> from the multiplatinum CD,
                <strong> “College Dropout”</strong> by{" "}
                <strong>Kanye West</strong>. In the last season of the hit
                series <strong>The Sopranos</strong>, his composition{" "}
                <strong>“I Just Wanna Stop"</strong> was included in one of its
                final episodes. Recently Ross produced and wrote a three CD
                package of 40 songs entitled <strong>“Sounds Of Life”</strong>,
                created for meditation and relaxation. In 2009 Ross produced a
                Christmas CD entitled <strong>“Whiter Than Snow”</strong>{" "}
                performed by <strong>Joni Eareckson Tada</strong>. In 2010/2011
                Ross composed music for the film <strong>“The Traveler"</strong>{" "}
                starring Val Kilmer and distributed by{" "}
                <strong>Paramount Pictures</strong>. Ross completed music for
                the independent film entitled <strong>“Refuge"</strong>,
                directed by <strong>Mark Medoff</strong> (wrote the screenplay
                for “Children of a Lesser God”, “Clara’s Heart”, “City Of Joy”)
                starring <strong>Linda Hamilton</strong> and{" "}
                <strong>Chris McDonald</strong>.
              </Typography>
            </Box>
          </Grid>
          {/* Continued */}
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex" }}>
            <Box
              sx={{
                mt: "1.5em",
              }}
            >
              <Typography
                sx={{ fontSize: "1.1rem", fontWeight: 400, color: "gray" }}
              >
                <Box sx={{ float: "right", pl: 1.5, pb: 1 }}>
                  <img
                    style={{
                      width: 270,
                      height: 250,
                    }}
                    src={img5}
                    alt=""
                  />
                </Box>
                Released in 2014, Ross produced the{" "}
                <strong>Gino Vannelli</strong> DVD entitled
                <strong>“LIVE IN LA”</strong>. Live In LA, remained an{" "}
                <strong>Amazon top seller</strong> for many months. Released in
                2015, Ross produced the <strong>Take 6</strong> CD entitled{" "}
                <strong>“BELIEVE”</strong>, co-writing and arranging multiple
                songs. Released in 2016, Ross produced and edited the Live
                Concert <strong>Brian McKnight</strong> Blu Ray entitled{" "}
                <strong>“An Evening with Brian McKnight”</strong>. Released in
                2017, Ross produced, co-wrote, and arranged multiple songs for
                the top female Hawaiian recording artist,{" "}
                <strong>ANUHEA</strong>. Her new CD
                <strong> “FOLLOW ME”</strong> entered the itunes R&B chart at
                number 10. Ross co-wrote, co-arranged and co-produced, Anuhea‘s
                first single entitled <strong>“We Make It Look Easy”</strong>{" "}
                with producer, songwriter <strong>Mikal Blue</strong>. (writer
                and producer of{" "}
                <strong>
                  Colby Caillat, Five For Fighting, Jason Mraz OneRepublic
                </strong>
                ,) In 2017, the Ross Vannelli song{" "}
                <strong>“I Just Wanna Stop”</strong> was included in the{" "}
                <strong>Netflix </strong>
                series <strong>“MIND HUNTER”</strong>. In 2017, “I Just Wanna
                Stop” was inducted into the Canadian Songwriters Hall of Fame.
                In 2019 Ross produced and co-wrote the songs{" "}
                <strong>Brighter Days</strong> and
                <strong> Not Ready To Say Goodbye</strong> for the{" "}
                <strong>Jamie Alimorad</strong> project entitled
                <strong> “This is Tomorrow Calling”</strong>. The song Brighter
                Days hit top 10 on many Indie Charts. Currently Ross is
                producing and composing multiple songs for Jamie Alimorad. In
                February 2022 the Jamie Alimorad song{" "}
                <strong>“Give a Little Lovin”</strong> hit number one on the New
                Music Weekly charts and remained numbered one for over 15
                consecutive weeks. Ross is producing and writing songs and has
                created music videos for multiple artists including Canadian
                artist{" "}
                <strong>
                  Jamie McRoberts, Jeff Pike, John Oates, Davey Johnstone and
                  Macy Gray
                </strong>{" "}
                to name a few.
              </Typography>
            </Box>
          </Grid>

          {/* Awards and Honors */}
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex" }}>
            <Box>
              <h2
                style={{
                  margin: 0,
                  color: "gray",
                  fontWeight: "bold",
                  fontSize: "1.8rem",
                  lineHeight: 1.235,
                  letterSpacing: "0.00735em",
                  marginTop: "1em",
                }}
              >
                Awards and Honors
              </h2>
              <Divider sx={{ background: "gray", mb: 2 }}></Divider>

              <Typography
                sx={{
                  fontSize: "1.1rem",
                  fontWeight: 400,
                  color: "gray",
                  marginBottom: "1.5em",
                }}
              >
                <Box sx={{ float: "right", ml: 2, mb: 1 }}>
                  <img
                    style={{
                      width: 230,
                      height: 320,
                    }}
                    src={img2}
                    alt=""
                  />
                </Box>
                <strong>Across his extensive career</strong>, Ross has achieved
                many impressive awards and honors for his role in the music
                industry. Recorded in 1978, the “Brother to Brother” LP by Gino
                Vannelli on A&M records was certified RIAA Gold and Platinum by
                1979. Ross co-produced / co-arranged this LP and was the
                co-writer of the song “Love & Emotion”, and the composer of the
                song “I Just Wanna Stop”. Additionally, the first released
                single from the Brother to Brother LP, "I Just Wanna Stop" rose
                to the top of the Billboard charts and has attained more than
                four million plays to date according to BMI. In 1981 Gino, Joe &
                Ross were nominated by the National Academy of Recording Arts
                and Sciences for Best Instrumental Arrangement accompaning
                vocals for the song “Living Inside Myself” from the
                “Nightwalker” LP. With nominations in 1979, 1985, 1987, and
                1993, Ross and his brothers won Producer of the Year for the
                album “Brother to Brother” on A&M Records Canada. In 1985
                Polydor Records released the “Black Cars” LP by Gino Vannelli.
                Co-Produced and co-arranged by Ross and his brothers, Ross
                co-wrote the songs “It’s Over” and “Just A Motion Away”.
                Attaining worldwide success, this LP including the single “Black
                Cars” was certified Gold and Platinum in 1986 by the Canadian
                Recording Industry Association (CRIA).
              </Typography>

              <Typography
                sx={{
                  fontSize: "1.1rem",
                  fontWeight: 400,
                  color: "gray",
                  marginBottom: "3em",
                }}
              >
                <Box sx={{ float: "left", pr: 2.5, pb: 0.5 }}>
                  <img
                    style={{
                      width: 280,
                      height: 220,
                    }}
                    src={img3}
                    alt=""
                  />
                </Box>
                <strong>Released in 1980</strong> on ARC/Columbia Records and
                certified gold in the US by the RIAA, the album “Faces” by Earth
                Wind and Fire attained number 2 and number 10 on the Billboard
                Black and Pop albums charts. Ross co-wrote the musical
                composition “You Went Away”. All the California Raisins CDS
                produced, engineered and co-arranged by Ross were released on
                Priority Records in the US and A&M Records in Canada from 1987
                to 1990. “California Raisins Sing The Hits”, “Christmas with The
                California Raisins” and “Sweet, Delicious & Marvelous all
                reached success with “California Raisins Sing The Hits”, being
                certified Gold and Platinum by the RIAA and the CRIA. “Stay”
                co-produced and co-written by Ross was released as the second
                single from the LP “I Committ To Love” by Howard Hewett on
                Elektra Records. ASCAP presented this awarded to Hardstone Music
                for entering Top 10 on the Billboard R&B charts. Released on
                Capitol Records, the song “Surrender To Me”performed by Ann
                Wilson and Robin Zander from the film and soundtrack “Tequila
                Sunrise” brought Ross and Rockwood Music the BMI award for most
                performed song of 1990. The song “We Don’t Care” co-written by
                Ross from the CD “The College Dropout” by Kanye West was
                released in 2004 on Roc-A-Fella Records. The CD sold over 4
                million copies worldwide and was certified Gold and Platinum by
                the RIAA.
              </Typography>
              {/* <Grid container>
                <Grid
                  item
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    mb: "1.5em",
                  }}
                >
                  <img
                    style={{
                      width: 230,
                      height: 320,
                    }}
                    src={img6}
                    alt=""
                  />
                  <img
                    style={{
                      width: 230,
                      height: 320,
                    }}
                    src={img9}
                    alt=""
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    mb: "1.5em",
                  }}
                >
                  <img
                    style={{
                      width: 230,
                      height: 320,
                    }}
                    src={img10}
                    alt=""
                  />
                  <img
                    style={{
                      width: 230,
                      height: 320,
                    }}
                    src={img1}
                    alt=""
                  />
                </Grid>
                <Typography
                  sx={{
                    fontSize: "1.1rem",
                    fontWeight: 400,
                    color: "gray",
                    marginTop: "1.5em",
                    marginBottom: "1.5em",
                  }}
                >
                  <Box sx={{ float: "right", pl: 2.5, pb: 0.5 }}>
                    <img
                      style={{
                        width: 280,
                        height: 220,
                      }}
                      src={img11}
                      alt=""
                    />
                  </Box>
                  <strong>Released in 1980</strong> on ARC/Columbia Records and
                  certified gold in the US by the RIAA, the album “Faces” by
                  Earth Wind and Fire attained number 2 and number 10 on the
                  Billboard Black and Pop albums charts. Ross co-wrote the
                  musical composition “You Went Away”. All the California
                  Raisins CDS produced, engineered and co-arranged by Ross were
                  released on Priority Records in the US and A&M Records in
                  Canada from 1987 to 1990. “California Raisins Sing The Hits”,
                  “Christmas with The California Raisins” and “Sweet, Delicious
                  & Marvelous all reached success with “California Raisins Sing
                  The Hits”, being certified Gold and Platinum by the RIAA and
                  the CRIA. “Stay” co-produced and co-written by Ross was
                  released as the second single from the LP “I Committ To Love”
                  by Howard Hewett on Elektra Records. ASCAP presented this
                  awarded to Hardstone Music for entering Top 10 on the
                  Billboard R&B charts. Released on Capitol Records, the song
                  “Surrender To Me”performed by Ann Wilson and Robin Zander from
                  the film and soundtrack “Tequila Sunrise” brought Ross and
                  Rockwood Music the BMI award for most performed song of 1990.
                  The song “We Don’t Care” co-written by Ross from the CD “The
                  College Dropout” by Kanye West was released in 2004 on
                  Roc-A-Fella Records. The CD sold over 4 million copies
                  worldwide and was certified Gold and Platinum by the RIAA.
                </Typography>
              </Grid> */}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Biography;
