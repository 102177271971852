import * as React from "react";
import {
  Button,
  Card,
  Grid,
  Typography,
  Box,
  Container,
  Divider,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import img1 from "./../../images/Covers1.jpg";
import img3 from "./../../images/GinoMgmt3.jpg";
import img4 from "./../../images/GinoMgmt1.jpg";
import img5 from "./../../images/GinoMgmt2.jpg";
import img6 from "./../../images/SRGLogo.png";
import img7 from "./../../images/JamiePromo.jpg";
import img8 from "./../../images/AGameNew.jpg";
import SMStack from "../SMStack/SMStack";
import { Icon } from "@iconify/react";

const PubContent = () => {
  let navigate = useNavigate();
  return (
    <div style={{ background: "white" }}>
      <div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background: "whitesmoke",
            mb: "4em",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              fontSize: "3rem",
              fontWeight: 400,
              mt: ".5em",
            }}
          >
            <strong>Gino Vannelli</strong>
          </Typography>
          <Typography
            sx={{
              display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
              fontSize: "1.1rem",
              fontWeight: 400,
              color: "gray",
              textAlign: "center",
              pl: "10em",
              pr: "10em",
            }}
          >
            <strong>
              COA Productions has represented Gino Vannelli and has organized
              all his events since 2008. For all bookings including TV, film,
              radio interviews and live performances please contact COA
              Productions at coapro@verizon.net. For more information or to
              visit Gino's personal website and / or social medias, click the
              icons below or visit www.GinoV.com{" "}
            </strong>
            <Divider sx={{ background: "black", mt: "1em" }}></Divider>
          </Typography>
          <Typography
            sx={{
              display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
              fontSize: ".9rem",
              fontWeight: 400,
              color: "gray",
              textAlign: "center",
              pl: "5em",
              pr: "5em",
              alignItems: "center",
            }}
          >
            <strong>
              COA Productions has represented Gino Vannelli and has organized
              all his events since 2008. For all bookings including TV, film,
              radio interviews and live performances please contact COA
              Productions at coapro@verizon.net. For more information or to
              visit Gino's personal website and / or social medias, click the
              icons below or visit www.GinoV.com{" "}
            </strong>
            <Divider sx={{ background: "black", mt: "1em" }}></Divider>
          </Typography>
          <Stack
            sx={{ justifyContent: "center", mt: "1em", mb: "2em" }}
            direction="row"
            spacing={0.3}
          >
            <Button
              href="https://www.instagram.com/gino.vannelli/"
              aria-label="instagram"
              rel="noopener"
              target="_blank"
              sx={{
                fontSize: 40,
                color: "purple",
                "&:hover": {
                  cursor: "pointer",
                  background: "#F3BE12",
                  color: "white",
                },
              }}
            >
              <Icon icon="bi:instagram" />
            </Button>

            <Button
              href="https://www.facebook.com/GinoVannelliOfficial/"
              aria-label="facebook"
              rel="noopener"
              target="_blank"
              sx={{
                fontSize: 40,
                color: "blue",
                "&:hover": {
                  cursor: "pointer",
                  background: "#F3BE12",
                },
              }}
            >
              <Icon icon="bi:facebook" />
            </Button>

            <Button
              href="https://www.youtube.com/watch?v=UacFHobHyus"
              aria-label="youtube"
              rel="noopener"
              target="_blank"
              sx={{
                fontSize: 40,
                color: "red",
                "&:hover": {
                  cursor: "pointer",
                  background: "#F3BE12",
                  color: "white",
                },
              }}
            >
              <Icon icon="bi:youtube" />
            </Button>
            <Button
              href="https://ginov.com/"
              aria-label="website"
              rel="noopener"
              target="_blank"
              sx={{
                fontSize: 40,
                color: "black",
                "&:hover": {
                  cursor: "pointer",
                  background: "#F3BE12",
                  color: "white",
                },
              }}
            >
              <Icon icon="mdi:web" />
            </Button>
          </Stack>
        </Box>
        <Grid container sx={{ mb: "5em" }}>
          {/*  Pictures For Lg +  */}

          {/*  Desc. For Lg -  */}
          <Container>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: { lg: "flex", md: "flex", sm: "flex", xs: "none" },
              }}
            ></Grid>
          </Container>

          {/* Current Albums For Lg + */}
          <Grid
            item
            lg={6}
            sx={{
              display: { lg: "flex", md: "none", sm: "none", xs: "none" },
              justifyContent: "right",
              mt: "2em",
              mb: "2em",
            }}
          >
            <a
              style={{ textDecoration: "none" }}
              href="https://srgstore.com/collections/cd/products/gino-vannelli-more-of-a-good-thing"
              aria-label="GinoV.com"
              rel="noopener"
              target="_blank"
              text-Decoration="none!important"
            >
              <Card
                onClick={() => navigate("")}
                sx={{
                  display: "flex",
                  background: "#F1F1F1",
                  marginRight: ".5em",
                  width: 770,
                  height: 270,
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <img
                  onClick={() => navigate("")}
                  style={{
                    display: "flex",
                    width: 300,
                    height: 270,
                  }}
                  src={img4}
                  alt=""
                />
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    fontSize: "1.7rem",
                    fontWeight: "bold",
                    color: "black",
                    ml: "3em",
                    mt: ".5em",
                  }}
                >
                  "(More Of) A Good Thing"<br></br> - Gino Vannelli
                  <Divider
                    sx={{ display: "flex", background: "black", mt: ".2em" }}
                  ></Divider>
                  <Typography
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "1.3rem",
                      color: "gray",
                      mt: "1em",
                    }}
                  >
                    Now available <br></br>from SRG Records
                  </Typography>
                  <img
                    style={{
                      display: "flex",
                      marginTop: "1em",
                      marginLeft: "3.2em",
                      width: 135,
                      height: 40,
                    }}
                    src={img6}
                    alt=""
                  />
                </Typography>
              </Card>
            </a>
          </Grid>
          <Grid
            item
            lg={6}
            sx={{
              display: { lg: "flex", md: "none", sm: "none", xs: "none" },
              justifyContent: "left",
              mt: "2em",
              mb: "2em",
            }}
          >
            <a
              style={{ textDecoration: "none" }}
              href="https://srgstore.com/collections/cd/products/gino-vannelli-wilderness-road"
              aria-label="GinoV.com"
              rel="noopener"
              target="_blank"
              text-Decoration="none!important"
            >
              <Card
                onClick={() => navigate("")}
                sx={{
                  display: "flex",
                  background: "#F1F1F1",
                  marginLeft: ".5em",
                  width: 770,
                  height: 270,
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <img
                  style={{
                    display: "flex",
                    width: 300,
                    height: 270,
                  }}
                  src={img5}
                  alt=""
                />
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    fontSize: "1.7rem",
                    fontWeight: "bold",
                    color: "black",
                    ml: "4.4em",
                    mt: ".5em",
                  }}
                >
                  "Wilderness Road" <br></br>- Gino Vannelli
                  <Divider sx={{ background: "black", mt: ".2em" }}></Divider>
                  <Typography
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                      fontSize: "1.3rem",
                      fontWeight: "bold",
                      color: "gray",
                      mt: "1em",
                    }}
                  >
                    Now available <br></br>from SRG Records
                  </Typography>
                  <img
                    style={{
                      display: "flex",
                      marginTop: "1em",
                      marginLeft: "1.5em",
                      width: 135,
                      height: 40,
                    }}
                    src={img6}
                    alt=""
                  />
                </Typography>
              </Card>
            </a>
          </Grid>
          {/*  Current Albums For Md -  */}
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: { lg: "none", md: "flex", sm: "flex", xs: "none" },
              justifyContent: "center",
              mb: "2em",
            }}
          >
            <a
              style={{ textDecoration: "none" }}
              href="https://srgstore.com/collections/cd/products/gino-vannelli-more-of-a-good-thing"
              aria-label="GinoV.com"
              rel="noopener"
              target="_blank"
              text-Decoration="none!important"
            >
              <Card
                onClick={() => navigate("")}
                sx={{
                  display: "flex",
                  background: "#F1F1F1",
                  width: 770,
                  height: 270,
                  mt: "1.5em",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <img
                  style={{
                    display: "flex",
                    width: 300,
                    height: 270,
                  }}
                  src={img4}
                  alt=""
                />
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    fontSize: "1.6rem",
                    fontWeight: "bold",
                    color: "black",
                    ml: "2.3em",
                    mt: "1em",
                  }}
                >
                  "A Good Thing" - Gino Vannelli
                  <Divider sx={{ background: "black", mt: ".2em" }}></Divider>
                  <Typography
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "1.3rem",
                      color: "gray",
                      mt: "1em",
                    }}
                  >
                    Now available <br></br>from SRG Records
                  </Typography>
                  <img
                    style={{
                      display: "flex",
                      marginTop: "1.6em",
                      marginLeft: "3.9em",
                      width: 135,
                      height: 40,
                    }}
                    src={img6}
                    alt=""
                  />
                </Typography>
              </Card>
            </a>
          </Grid>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: { lg: "none", md: "flex", sm: "flex", xs: "none" },
              justifyContent: "center",
              mb: "2em",
            }}
          >
            <a
              style={{ textDecoration: "none" }}
              href="https://srgstore.com/collections/cd/products/gino-vannelli-wilderness-road"
              aria-label="GinoV.com"
              rel="noopener"
              target="_blank"
              text-Decoration="none!important"
            >
              <Card
                onClick={() => navigate("")}
                sx={{
                  display: "flex",
                  background: "#F1F1F1",
                  width: 770,
                  height: 270,
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <img
                  style={{
                    display: "flex",
                    width: 300,
                    height: 270,
                  }}
                  src={img5}
                  alt=""
                />
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    fontSize: "1.6rem",
                    fontWeight: "bold",
                    color: "black",
                    ml: "1.6em",
                    mt: "1em",
                  }}
                >
                  "Wilderness Road" - Gino Vannelli
                  <Divider sx={{ background: "black", mt: ".2em" }}></Divider>
                  <Typography
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "1.3rem",
                      color: "gray",
                      mt: "1em",
                    }}
                  >
                    Now available <br></br>from SRG Records
                  </Typography>
                  <img
                    style={{
                      display: "flex",
                      marginTop: "1.6em",
                      marginLeft: "4.75em",
                      width: 135,
                      height: 40,
                    }}
                    src={img6}
                    alt=""
                  />
                </Typography>
              </Card>
            </a>
          </Grid>
          {/*  Current Albums For Xs -  */}
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: { lg: "none", md: "none", sm: "none", xs: "flex" },
              justifyContent: "center",
              mb: "2em",
            }}
          >
            <a
              style={{ textDecoration: "none" }}
              href="https://srgstore.com/collections/cd/products/gino-vannelli-more-of-a-good-thing"
              aria-label="GinoV.com"
              rel="noopener"
              target="_blank"
              text-Decoration="none!important"
            >
              <Card
                onClick={() => navigate("")}
                sx={{
                  display: "flex",
                  background: "#F1F1F1",
                  width: 350,
                  height: 150,
                  mt: "1.5em",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <img
                  style={{
                    display: "flex",
                    width: 150,
                    height: 150,
                  }}
                  src={img4}
                  alt=""
                />
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                    color: "black",
                    ml: "2em",
                    mt: ".5em",
                  }}
                >
                  "A Good Thing"
                  <Divider sx={{ background: "black", mt: ".3em" }}></Divider>
                  <Typography
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "1rem",
                      color: "gray",
                      mt: ".1em",
                    }}
                  >
                    Now available
                  </Typography>
                  <img
                    style={{
                      display: "flex",
                      marginTop: "1em",
                      marginLeft: ".8em",
                      width: 95,
                      height: 30,
                    }}
                    src={img6}
                    alt=""
                  />
                </Typography>
              </Card>
            </a>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: { lg: "none", md: "none", sm: "none", xs: "flex" },
              justifyContent: "center",
              mb: "2em",
            }}
          >
            <a
              style={{ textDecoration: "none" }}
              href="https://srgstore.com/collections/cd/products/gino-vannelli-wilderness-road"
              aria-label="GinoV.com"
              rel="noopener"
              target="_blank"
              text-Decoration="none!important"
            >
              <Card
                onClick={() => navigate("")}
                sx={{
                  display: "flex",
                  background: "#F1F1F1",
                  width: 350,
                  height: 150,
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <img
                  style={{
                    display: "flex",
                    width: 150,
                    height: 150,
                  }}
                  src={img5}
                  alt=""
                />
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                    color: "black",
                    ml: "1.2em",
                    mt: ".5em",
                  }}
                >
                  "Wilderness Road"
                  <Divider sx={{ background: "black", mt: ".3em" }}></Divider>
                  <Typography
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "1rem",
                      color: "gray",
                      mt: ".1em",
                    }}
                  >
                    Now available
                  </Typography>
                  <img
                    style={{
                      display: "flex",
                      marginTop: "1em",
                      marginLeft: "1.5em",
                      width: 95,
                      height: 30,
                    }}
                    src={img6}
                    alt=""
                  />
                </Typography>
              </Card>
            </a>
          </Grid>
        </Grid>
      </div>
      <div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background: "whitesmoke",
          }}
        >
          <Typography
            sx={{
              display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
              justifyContent: "center",
              textAlign: "center",
              fontSize: "3rem",
              fontWeight: 400,
              mt: ".5em",
            }}
          >
            <strong>Jamie McRoberts</strong>
          </Typography>
          <Typography
            sx={{
              display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
              justifyContent: "center",
              textAlign: "center",
              fontSize: "3rem",
              fontWeight: 400,
              mt: ".5em",
            }}
          >
            <strong>
              Jamie <br></br> McRoberts
            </strong>
          </Typography>
          <Typography
            sx={{
              display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
              fontSize: "1.1rem",
              fontWeight: 400,
              color: "gray",
              textAlign: "center",
              pl: "10em",
              pr: "10em",
            }}
          >
            <strong>
              COA Productions has represented Jamie McRoberts since 2022. For
              all inquiries pertaining to Jamie McRoberts or Ross Vannelli,
              please contact COA Productions at coapro@verizon.net. For more
              information or to visit Jamie's personal website and / or social
              medias, click the icons below or visit www.JamieMcRoberts.com{" "}
            </strong>
            <Divider sx={{ background: "black", mt: "1em" }}></Divider>
          </Typography>
          <Typography
            sx={{
              display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
              fontSize: ".9rem",
              fontWeight: 400,
              color: "gray",
              textAlign: "center",
              pl: "5em",
              pr: "5em",
              alignItems: "center",
            }}
          >
            <strong>
              COA Productions has represented Jamie McRoberts since 2022. For
              all inquiries pertaining to Jamie McRoberts or Ross Vannelli,
              please contact COA Productions at coapro@verizon.net. For more
              information or to visit Jamie's personal website and / or social
              medias, click the icons below or visit www.JamieMcRoberts.com{" "}
            </strong>
            <Divider sx={{ background: "black", mt: "1em" }}></Divider>
          </Typography>
          <Stack
            sx={{ justifyContent: "center", mt: "1em", mb: "2em" }}
            direction="row"
            spacing={0.3}
          >
            <Button
              href="https://www.instagram.com/jamieamcroberts77/"
              aria-label="instagram"
              rel="noopener"
              target="_blank"
              sx={{
                fontSize: 40,
                color: "purple",
                "&:hover": {
                  cursor: "pointer",
                  background: "#F3BE12",
                  color: "white",
                },
              }}
            >
              <Icon icon="bi:instagram" />
            </Button>

            <Button
              href="https://www.facebook.com/jamieamcroberts77"
              aria-label="facebook"
              rel="noopener"
              target="_blank"
              sx={{
                fontSize: 40,
                color: "blue",
                "&:hover": {
                  cursor: "pointer",
                  background: "#F3BE12",
                },
              }}
            >
              <Icon icon="bi:facebook" />
            </Button>

            <Button
              href="https://www.youtube.com/@coaproductions"
              aria-label="youtube"
              rel="noopener"
              target="_blank"
              sx={{
                fontSize: 40,

                color: "red",
                "&:hover": {
                  cursor: "pointer",
                  background: "#F3BE12",
                  color: "white",
                },
              }}
            >
              <Icon icon="bi:youtube" />
            </Button>
            <Button
              href="https://jamiemcroberts.com/"
              aria-label="website"
              rel="noopener"
              target="_blank"
              sx={{
                fontSize: 40,
                color: "black",
                "&:hover": {
                  cursor: "pointer",
                  background: "#F3BE12",
                  color: "white",
                },
              }}
            >
              <Icon icon="mdi:web" />
            </Button>
          </Stack>
        </Box>
        <Grid
          container
          columnSpacing={0}
          sx={{ display: "flex", pl: "4em", pr: "4em" }}
        >
          <Grid
            item
            xl={6}
            lg={6}
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center", mt: "5em" }}>
              <Box
                sx={{
                  display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
                }}
              >
                <img
                  style={{
                    minWidth: 240,
                    height: 400,
                  }}
                  src={img8}
                  alt="A Game Promotion"
                />
              </Box>
              <Box
                sx={{
                  display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
                }}
              >
                <img
                  style={{
                    maxWidth: 300,
                    maxheight: 450,
                  }}
                  src={img8}
                  alt="A Game Promotion Promotion"
                />
              </Box>
              <Box
                sx={{
                  display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
                  flexDirection: "column",
                  justifyContent: "center",
                  mt: "2.4em",
                  ml: "2em",
                  mb: "2em",
                  "&:hover": {
                    cursor: "default",
                  },
                }}
              >
                <h2
                  className="songName"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: "gray",
                  }}
                >
                  Bring Me Your{" "}
                  <span style={{ color: "red", marginLeft: ".2em" }}>
                    'A' Game
                  </span>
                </h2>

                <Button
                  href=" https://distrokid.com/hyperfollow/jamiemcroberts/bring-me-your-a-game-2 "
                  aria-label=""
                  rel="noopener"
                  target="_blank"
                  className="button"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "solid 4px",
                    borderColor: "#F3BE12",
                    mt: "1em",
                    ml: "9em",

                    width: "250px",
                    "&:hover": {
                      cursor: "pointer",
                      background: "#F3BE12",
                    },
                  }}
                >
                  <h3 style={{ color: "gray", marginTop: ".2em" }}>
                    Stream + Buy
                  </h3>
                </Button>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: "1em",
                    ml: "1em",
                  }}
                >
                  <SMStack />
                </Box>
                <Box
                  sx={{
                    display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
                    mt: "3.4em",
                    mb: "0em",
                    width: "500px",
                    "&:hover": {
                      cursor: "default",
                    },
                  }}
                >
                  <p style={{ color: "gray", fontWeight: "bold" }}>
                    Bring Me Your 'A' Game is Jamie McRoberts first ever album
                    set to release May 26th, 2023. Produced by Ross Vannelli.
                    Jamie and Ross penned eleven original tunes for her debut
                    album. Stream + Buy now in anticipation of the album's
                    release later next month!
                  </p>
                </Box>
              </Box>
            </Box>
            {/* Music section for Sm - */}
            <Box
              sx={{
                display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
                flexDirection: "column",
                justifyContent: "center",
                mt: "2em",
                mb: "2em",
                "&:hover": {
                  cursor: "default",
                },
              }}
            >
              <h2
                className="songName"
                style={{
                  display: "block",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "gray",
                }}
              >
                Bring Me Your{" "}
                <span style={{ color: "red", marginLeft: "0em" }}>
                  'A' Game
                </span>
              </h2>

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  href=" https://distrokid.com/hyperfollow/jamiemcroberts/bring-me-your-a-game-2 "
                  aria-label=""
                  rel="noopener"
                  target="_blank"
                  className="button"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    verticalAlign: "center",
                    border: "solid 4px",
                    borderColor: "#F3BE12",
                    mt: "1em",
                    width: "250px",
                    "&:hover": {
                      cursor: "pointer",
                      background: "#F3BE12",
                    },
                  }}
                >
                  <h3 style={{ color: "gray", marginTop: ".2em" }}>
                    Stream + Buy
                  </h3>
                </Button>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: "1em",
                  mb: "0em",
                }}
              >
                <SMStack />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center", mt: "5em" }}>
              <Box
                sx={{
                  display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
                }}
              >
                <img
                  style={{
                    minWidth: 240,
                    height: 400,
                  }}
                  src={img7}
                  alt="Just Like I Remember Promotion"
                />
              </Box>
              <Box
                sx={{
                  display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
                }}
              >
                <img
                  style={{
                    maxWidth: 300,
                    maxheight: 450,
                  }}
                  src={img7}
                  alt="Just Like I Remember Promotion"
                />
              </Box>
              <Box
                sx={{
                  display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
                  flexDirection: "column",
                  justifyContent: "center",
                  mt: "2.4em",
                  ml: "2em",
                  mb: "2em",
                  "&:hover": {
                    cursor: "default",
                  },
                }}
              >
                <h2
                  className="songName"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: "gray",
                  }}
                >
                  Just Like I Remember
                </h2>

                <Button
                  href=" https://distrokid.com/hyperfollow/jamiemcroberts/just-like-i-remember"
                  aria-label=""
                  rel="noopener"
                  target="_blank"
                  className="button"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "solid 4px",
                    borderColor: "#F3BE12",
                    mt: "1em",
                    ml: "9em",

                    width: "250px",
                    "&:hover": {
                      cursor: "pointer",
                      background: "#F3BE12",
                    },
                  }}
                >
                  <h3 style={{ color: "gray", marginTop: ".2em" }}>
                    Stream + Buy
                  </h3>
                </Button>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: "1em",
                    ml: "1em",
                  }}
                >
                  <SMStack />
                </Box>
                <Box
                  sx={{
                    display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
                    mt: "4.4em",
                    mb: "0em",
                    width: "500px",
                    "&:hover": {
                      cursor: "default",
                    },
                  }}
                >
                  <p style={{ color: "gray", fontWeight: "bold" }}>
                    "Just Like I Remember" is the first single from Jamie
                    McRoberts upcoming album entitled Bring Me Your ‘A’ Game,
                    set to release May 26th, 2023. Listen to this track now in
                    anticipation of her debuting album later next month!
                  </p>
                </Box>
              </Box>
            </Box>
            {/* Music section for Sm - */}
            <Box
              sx={{
                display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
                flexDirection: "column",
                justifyContent: "center",
                mt: "2em",
                mb: "2em",
                "&:hover": {
                  cursor: "default",
                },
              }}
            >
              <h2
                className="songName"
                style={{
                  display: "block",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "gray",
                }}
              >
                Just Like I Remember
              </h2>

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  href=" https://distrokid.com/hyperfollow/jamiemcroberts/just-like-i-remember"
                  aria-label=""
                  rel="noopener"
                  target="_blank"
                  className="button"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    border: "solid 4px",
                    borderColor: "#F3BE12",
                    mt: "1em",

                    width: "250px",
                    "&:hover": {
                      cursor: "pointer",
                      background: "#F3BE12",
                    },
                  }}
                >
                  <h3 style={{ color: "gray", marginTop: ".2em" }}>
                    Stream + Buy
                  </h3>
                </Button>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: "1em",
                  mb: "3em",
                }}
              >
                <SMStack />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default PubContent;
