import React from "react";
import MgmtContent from "../components/Grids/MgmtContent";
import NavigationBar from "../components/Grids/NavigationBar";
import NavigationBarMobile from "../components/Grids/NavigationBarMobile";
import ContactMeHero from "./../components/Grids/ContactMeHero";

const ManagementPage = () => {
  return (
    <div>
      <NavigationBarMobile />
      <NavigationBar />
      <MgmtContent />
      <ContactMeHero />
    </div>
  );
};

export default ManagementPage;
