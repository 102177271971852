import * as React from "react";
import Stack from "@mui/material/Stack";
import { Icon } from "@iconify/react";

const SMStack = () => {
  return (
    <Stack direction="row" spacing={0} sx={{}}>
      <Icon icon="bi:youtube" />
    </Stack>
  );
};

export default SMStack;
